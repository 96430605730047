<template>
  <v-dialog v-model="dialog" :persistent="loading" width="600">
    <v-card>
      <v-card-title>Hae vuokrakohteen tilitysraportti</v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="form">
            <label>Raportin aikaväli</label>
            <v-row dense>
              <v-col cols="12" sm="6">
                <v-menu
                  ref="startMenu"
                  v-model="startMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :value="formatDate(dates.startDate)"
                      outlined
                      dense
                      label="Alkaen"
                      v-bind="attrs"
                      v-on="on"
                      append-icon="mdi-calendar"
                      ref="startDateFormatted"
                      v-prevent-manual-input
                      :readonly="true"
                      hide-details
                      :rules="validations.required"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dates.startDate"
                    :allowed-dates="allowedDates"
                    type="month"
                    first-day-of-week="1"
                    @input="startMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="6">
                <v-menu
                  ref="endMenu"
                  v-model="endMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :value="formatDate(dates.endDate)"
                      outlined
                      dense
                      label="Päättyen"
                      v-bind="attrs"
                      v-on="on"
                      append-icon="mdi-calendar"
                      v-prevent-manual-input
                      :readonly="true"
                      hide-details
                      :rules="validations.required"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dates.endDate"
                    type="month"
                    :allowed-dates="allowedDates"
                    first-day-of-week="1"
                    @input="endMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <label>Toimeksiantaja</label>
            <v-row dense>
              <v-col cols="12">
                <v-autocomplete
                  v-model="partnerId"
                  :items="activePartners"
                  item-text="name"
                  item-value="_id"
                  label="Liittyy toimeksiantajaan"
                  no-data-text="Nimi"
                  outlined
                  small-chips
                  dense
                  clearable
                  :search-input.sync="partnerInput"
                  @change="resetApartment"
                  hide-details
                >
                </v-autocomplete>
              </v-col>
            </v-row>

            <label>Vuokrakohde</label>
            <v-row dense>
              <v-col cols="12">
                <v-autocomplete
                  v-model="apartmentId"
                  :items="activeApartments"
                  :item-text="fullAddress"
                  item-value="_id"
                  label="Liittyy vuokrakohteeseen"
                  :no-data-text="partnerId ? 'Vuokrakohteen osoite tai *' : 'Vuokrakohteen osoite'"
                  outlined
                  small-chips
                  dense
                  :no-filter="apartmentInput === '*'"
                  :rules="validations.required"
                  :search-input.sync="apartmentInput"
                  @change="apartmentInput = ''"
                  @input.native="getApartments"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" v-if="!loading" outlined @click="dialog = false"> Poistu </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="submit()" :loading="loading">Hae raportti</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapActions, mapState, mapMutations } from "vuex";
import { debounce } from "../../utils/helpers";
import validations from "@/validations";
import moment from "moment";
import { getReportDatesAndTime } from "@/utils/reportHelpers";

export default {
  mixins: [mixins],

  props: {
    value: { type: Boolean, default: false },
  },

  data() {
    return {
      loading: false,
      startMenu: false,
      endMenu: false,
      dates: { startDate: null, endDate: null },
      apartmentId: null,
      partnerId: null,
      apartmentInput: "",
      partnerInput: "",
      validations,
    };
  },

  computed: {
    ...mapState("account", ["currentUser"]),
    ...mapState("apartment", ["activeApartments"]),
    ...mapState("partner", ["activePartners"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) this.resetForm();
        this.$emit("input", value);
      },
    },
  },

  watch: {
    apartmentInput: debounce(function (newVal) {
      if (this.partnerId) {
        this.searchApartment(
          `/api/v1/apartment/search-apartment?search=${newVal}&partnerId=${this.partnerId}`
        );
      } else {
        this.searchApartment(`/api/v1/apartment/search-apartment?search=${newVal}`);
      }
    }, 1000),

    partnerInput: debounce(function (newVal) {
      this.searchPartner(`/api/v1/partner/search-partner?search=${newVal}`);
    }, 1000),

    "dates.startDate"(val) {
      if (val) this.dates.startDate = moment(val).startOf("month").format("YYYY-MM-DD");
    },

    "dates.endDate"(val) {
      if (val) this.dates.endDate = moment(val).endOf("month").format("YYYY-MM-DD");
    },
  },

  methods: {
    ...mapActions("apartment", ["searchApartment"]),
    ...mapMutations("apartment", ["setActiveApartments"]),
    ...mapActions("partner", ["searchPartner"]),
    ...mapActions("report", ["getOutgoingPaymentIncomeReport"]),

    fullAddress(item) {
      return item.apartmentNumber ? item.address + ", " + item.apartmentNumber : item.address;
    },

    async getApartments(event) {
      this.apartmentInput = event.target.value;
    },

    allowedDates(val) {
      if (val) {
        const today = new Date(moment().format("YYYY-MM-DD"));
        const thisYear = today.getFullYear();
        const thisMonth = today.getMonth();
        const valYear = new Date(val).getFullYear();
        const valMonth = new Date(val).getMonth();

        if (valYear < thisYear) return true;
        else if (thisYear == valYear && valMonth <= thisMonth) return true;
        else return false;
      }
      return false;
    },

    resetForm() {
      if (this.$refs.form) this.$refs.form.reset();
      this.dates = { startDate: null, endDate: null };
    },

    resetApartment() {
      this.apartmentInput = "";
      this.apartmentId = null;
      this.setActiveApartments([]);
      if (this.$refs.form) this.$refs.form.resetValidation();
    },

    async submit() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;

          const data = await this.getOutgoingPaymentIncomeReport({
            dates: this.dates,
            apartmentId: this.apartmentId,
          });

          const { formattedDates, reportRangeFormatted, reportDateFormatted } =
            getReportDatesAndTime(this.dates);

          // Get table items
          const tableItems = data.invoiceData.map((el) => {
            return {
              Maksukuukausi: `${el.monthName} ${el.year}`,
              Vuokra: el.totalRent,
              Kulut: Math.abs(el.totalReceipts),
              Tilitetty: Math.abs(el.totalSettlements),
              Lisätiedot: "",
            };
          });

          const additionalInfo = [
            `Vuokratuloselvitys`,
            `Aikaväliltä: ${reportRangeFormatted}`,
            `Muodostettu: ${reportDateFormatted}`,
          ];

          const sumColumns = ["Vuokra", "Kulut", "Tilitetty"];
          const condominium = `${data.condominium}, ${data.businessId}`;
          const address = `${data.address}, ${data.zipCode} ${data.city}`;
          const owners = data.owners;

          const contracts = [];

          data.contracts.forEach((c) => {
            const start = c.startDate ? `${this.formatDate(c.startDate)}` : "";
            const end = c.endDate ? `${this.formatDate(c.endDate)}` : "";

            if (start) {
              contracts.push({
                text: `${c.tenant}, ${c.govid}, vuokrasopimus ${this.formatDate(
                  c.startDate
                )} - ${end}`,
                size: 12,
              });
            }
          });

          const user = this.currentUser;

          const customRows = {
            beforeDataTable: [
              { skip: 1 },
              { text: owners, bold: true, size: 13 },
              { skip: 2 },
              { text: condominium, bold: true, size: 13 },
              { text: address, bold: true, size: 13 },
              { skip: 2 },
              { text: "Vuokralaiset", bold: true, size: 13 },
              ...contracts,
              { skip: 2 },
            ],
            afterDataTable: [
              { skip: 2 },
              { text: "Lisätiedot", bold: true },
              { skip: 2 },
              { text: user.company, bold: true },
              { text: user.name },
              { text: `${user.address}, ${user.zipCode} ${user.city}` },
              { text: `${user.phone}, ${user.email}` },
            ],
          };

          const buffer = await this.createExcelBuffer(tableItems, {
            addInfo: true,
            additionalInfo,
            sumColumns,
            customRows,
            headerHeightDefault: 20,
            cellHeightDefault: 15,
            customSymbols: {
              Vuokra: "€",
              Kulut: "€",
              Tilitetty: "€",
            },
            customSumCols: {
              1: "Yhteensä",
            },
            drawBorders: true,
          });

          this.downloadExcel(
            buffer,
            `Vuokratuloselvitys_${data.address}`,
            formattedDates.start,
            formattedDates.end
          );

          this.loading = false;
        } catch (err) {
          this.loading = false;
          this.showPopup(err, "error");
        }
      }
    },
  },
};
</script>

<style scoped>
.v-card__title .headline {
  font-weight: 300;
}
</style>
