<template>
  <v-card>
    <v-container>
      <label>Haetaan vain aikavälillä voimassa olleet sopimukset</label>
      <!-- Search by dates -->
      <search-by-dates
        ref="searchByDates"
        :showSearchBy="false"
        :checkStartDate="allowedStartDatesFunction"
        :checkEndDate="allowedEndDatesFunction"
        :startDateRequired="true"
        :endDateRequired="true"
      ></search-by-dates>

      <v-btn color="primary mt-1" @click="getDataFromApi">Hae</v-btn>
    </v-container>

    <v-data-table
      :headers="headers"
      :items="filteredItems"
      :footer-props="globalValues.footerProps"
      :items-per-page="getDefaultTableRows()"
      :loading="loading"
    >
      <template v-slot:top>
        <v-container>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="search"
                label="Osoite tai vuokralainen"
                single-line
                hide-details
                solo
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <!-- Apartment -->
      <template #[`item.apartment`]="{ item }">
        <router-link style="text-decoration: none" :to="`/apartment/overview/${item.apartmentId}`">
          <span>{{ getFullAddress(item) }} </span>
        </router-link>
      </template>

      <!-- owners -->
      <template #[`item.owners`]="{ item }">
        <span class="error--text" v-if="item.owners.length == 0">Ei omistajia</span>
        <div v-else v-for="(owner, idx) in item.owners" :key="'a' + idx">
          <router-link
            v-if="owner.onModel == 'Partner'"
            style="text-decoration: none"
            :to="`/partner/overview/${owner.ownerId}`"
          >
            <span>{{ owner.name }}</span>
          </router-link>
          <span v-else>{{ owner.name }}</span>
        </div>
      </template>

      <!-- contracts -->
      <template #[`item.contracts`]="{ item }">
        <div class="d-flex align-center">
          <v-icon class="mr-1" :color="hasValidContract(item) ? 'success' : 'error'" small
            >mdi-circle</v-icon
          >

          <span class="error--text" v-if="item.contracts.length == 0">Ei sopimuksia</span>

          <div v-else>
            <div v-for="(c, idx) in item.contracts" :key="'a' + idx">
              <p>{{ c.tenant }} (sop. {{ c.contractNumber }})</p>
              <p>
                {{ formatDate(c.startDate) }}
                <span v-if="c.revokedDate || c.endDate"
                  >- {{ c.revokedDate ? formatDate(c.revokedDate) : formatDate(c.endDate) }}</span
                >
              </p>
            </div>
          </div>
        </div>
      </template>

      <!-- assignment -->
      <template #[`item.assignment`]="{ item }">
        <span class="error--text" v-if="!item.assignment">Ei toimeksiantosopimusta</span>
        <div v-else>
          <p>Alkaa: {{ formatDate(item.assignment.startDate) }}</p>
          <p>Päättyy: {{ item.assignment.endDate ? formatDate(item.assignment.endDate) : "" }}</p>
        </div>
      </template>

      <!-- total invoicing including taxes -->
      <template #[`item.totalAmountInvoices`]="{ item }">
        <span>{{ formatCurrency(item.totalAmountInvoices) }}</span>
      </template>

      <!-- interests -->
      <template #[`item.interests`]="{ item }">
        <span>{{ formatCurrency(item.totalAmountInvoices - item.productsAmount) }}</span>
      </template>

      <!-- all invoice products, invoices minus refundinvoices -->
      <template #[`item.tenantGoal`]="{ item }">
        <td :class="{ 'highlight-border': !ownerGoalMatchesTenantGoal(item) }">
          <span class="font-weight-bold">{{ formatCurrency(item.productsAmount) }}</span>
        </td>
      </template>

      <!-- openAmountInvoices-->
      <template #[`item.tenantState`]="{ item }">
        <span
          class="font-weight-bold"
          :class="item.openAmountInvoices === 0 ? 'success--text' : 'error--text'"
          >{{ formatCurrency(item.openAmountInvoices) }}</span
        >
      </template>

      <!-- payments invoices - payments refundinvoices -->
      <template #[`item.ownerGoal`]="{ item }">
        <td :class="{ 'highlight-border': !ownerGoalMatchesTenantGoal(item) }">
          <span class="font-weight-bold">{{ formatCurrency(calcOwnerGoal(item)) }}</span>
        </td>
      </template>

      <!-- paid to owners total -->
      <template #[`item.paidToOwner`]="{ item }">
        <span>{{ formatCurrency(Math.abs(item.paymentsTotal)) }}</span>
      </template>

      <!-- owner state-->
      <template #[`item.ownerState`]="{ item }">
        <span
          class="font-weight-bold"
          :class="roundDecimals(calcOwnerState(item), 1) === 0 ? 'success--text' : 'error--text'"
          ><span>{{
            roundDecimals(calcOwnerState(item), 1) < 0
              ? "Ylisuoritus"
              : roundDecimals(calcOwnerState(item), 1) > 0
              ? "Maksetaan"
              : ""
          }}</span>
          {{ formatCurrency(Math.abs(calcOwnerState(item))) }}</span
        >
      </template>

      <!-- receipts total-->
      <template #[`item.receiptsTotal`]="{ item }">
        <span>{{ formatCurrency(Math.abs(item.receiptsTotal)) }}</span>
      </template>

      <!-- Total receipts amount
      <template #[`item.receiptsAmount`]="{ item }">
        <v-tooltip v-if="item.receipts.length > 0" top max-width="350">
          <template #activator="{ on, attrs }">
            <div class="primary--text" style="cursor: pointer" v-bind="attrs" v-on="on">
              <span v-if="!includesInActiveReceipts(item)">Erittely</span>
              <span v-else>
                <v-icon color="warning" small>mdi-alert-circle</v-icon>
              </span>
            </div>
          </template>

          <h4 class="mb-1">Tuotteet</h4>
          <div v-for="(receipt, index) in item.receipts" :key="index + 'e'">
            <strong>{{ receipt.name }}</strong>
            <div v-for="(prod, index) in receipt.products" :key="index + 'f'">
              <span :class="{ 'joined-payment': !receipt.active }"
                >{{ prod.name }}: {{ formatCurrency(prod.count * prod.amount) }}</span
              >
            </div>
          </div>
        </v-tooltip>

        <span v-if="item.receipts.length > 0" class="error--text font-weight-bold">{{
          formatCurrency(item.receiptsAmount)
        }}</span>
      </template>

       Actions
      <template #[`item.actions`]="{ item }">
        <div class="action-grid-container">
          <v-icon
            v-if="isAuthorized('outgoingPayment', 'edit') && tab == 0"
            title="Muokkaa"
            small
            @click="
              $store.state.outgoingpayment.currentPayment = { ...item };
              editPaymentDialog = true;
            "
            >mdi-pencil</v-icon
          >
        </div>
      </template> -->

      <template #no-data>
        <h2>Ei sopimuksia valitulla aikavälillä</h2>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapActions, mapState, mapMutations } from "vuex";
import SearchByDates from "../Invoice/SearchByDates.vue";
import moment from "moment";
import globalValues from "@/configs/globalValues";

export default {
  title: "Lähtevät maksut",

  components: {
    SearchByDates,
  },

  props: ["tab"],

  mixins: [mixins],

  data: () => ({
    loading: false,
    search: "",
    headers: [
      { text: "Osoite", value: "apartment", sortable: false },
      { text: "Sopimukset", value: "contracts", sortable: false },
      { text: "Toimeksianto", value: "assignment" },
      { text: "Toimeksiantaja", value: "owners" },
      { text: "Saatavat", value: "totalAmountInvoices" },
      { text: "Korot", value: "interests" },
      { text: "Tavoite Vu", value: "tenantGoal", sortable: false },
      { text: "Tilanne Vu", value: "tenantState", sortable: false },
      { text: "Tavoite Va", value: "ownerGoal", sortable: false },
      { text: "Kulut", value: "receiptsTotal" },
      { text: "Maksetut Va", value: "paidToOwner" },
      { text: "Tilanne Va", value: "ownerState" },
    ],
    globalValues,
  }),

  computed: {
    ...mapState("outgoingpayment", ["checkList", "totalLength"]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
    ]),
    ...mapState("invoice", ["searchByDates"]),

    filteredItems() {
      if (this.search) {
        return this.checkList.filter((item) => {
          return (
            this.getFullAddress(item).toLowerCase().includes(this.search.toLowerCase()) ||
            this.getTenantNames(item).toLowerCase().includes(this.search.toLowerCase())
          );
        });
      } else {
        return this.checkList;
      }
    },
  },

  methods: {
    ...mapActions("outgoingpayment", ["getCheckList"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveMainSearchTerm"]),

    async getDataFromApi() {
      if (this.$refs.searchByDates.validate()) {
        this.loading = true;
        this.saveMainSearchTerm(this.search);

        const dates = {
          startDate: this.searchByDates.startDate,
          endDate: this.searchByDates.endDate,
        };

        await this.getCheckList(dates);
        this.loading = false;
      }
    },

    hasValidContract(item) {
      const currentDate = moment().format("YYYY-MM-DD");

      return item.contracts.some((contract) => {
        const startDate = moment(contract.startDate).format("YYYY-MM-DD");
        const endDate = contract.endDate ? moment(contract.endDate).format("YYYY-MM-DD") : null;
        const revokedDate = contract.revokedDate
          ? moment(contract.revokedDate).format("YYYY-MM-DD")
          : null;

        const isActive = moment(startDate).isSameOrBefore(currentDate);
        const isNotRevoked = !revokedDate || moment(revokedDate).isSameOrAfter(currentDate);
        const isNotExpired = !endDate || moment(endDate).isSameOrAfter(currentDate);

        return isActive && isNotExpired && isNotRevoked;
      });
    },

    getTenantNames(item) {
      let tenantString = "";
      item.contracts && item.contracts.length > 0
        ? item.contracts.forEach((el) => (tenantString += el.tenant))
        : "";

      return tenantString;
    },

    getFullAddress(item) {
      return item.apartmentNumber ? `${item.address}, ${item.apartmentNumber}` : `${item.address}`;
    },

    calcOwnerGoal(item) {
      return item.paymentInvoiceProductSum + item.paymentRefundInvoiceProductSum;
    },

    calcOwnerState(item) {
      return this.calcOwnerGoal(item) + item.receiptsTotal + item.paymentsTotal;
    },

    includesInActiveReceipts(item) {
      return item.receipts.filter((el) => el.active == false).length > 0;
    },

    allowedStartDatesFunction(val) {
      const today = moment().format("YYYY-MM-DD");
      if (moment(today).subtract(2, "years").isSameOrBefore(val)) {
        return true;
      } else return false;
    },

    allowedEndDatesFunction(val) {
      const today = moment().format("YYYY-MM-DD");
      if (moment(today).subtract(2, "years").isSameOrBefore(val)) {
        return true;
      } else return false;
    },

    ownerGoalMatchesTenantGoal(item) {
      return (
        this.roundDecimals(item.productsAmount) === this.roundDecimals(this.calcOwnerGoal(item))
      );
    },
  },
};
</script>

<style scoped>
.highlight-border {
  border: 1px solid var(--v-error-base) !important;
}
</style>
